<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-06-30 10:37
 * @Description: 登录
-->
<style lang="scss" scoped>
.login {
  width: 100%;
  height: calc(100vh - 8px);
  min-width: 1300px;
  box-sizing: border-box;
  padding-left: 90.64vh;
  position: relative;
  overflow: visible;

  &-bg {
    height: calc(100% + 8px);
    position: absolute;
    top: 0;
    left: 0;
  }

  &-container {
    width: 50.04%;
    min-width: 548px;
    max-height: calc(100% + 8px);
    overflow: auto;
    padding: 50px 50px;
    padding-right: calc(50.04% - 548px);
    box-sizing: border-box;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 49.96%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    flex-direction: column;

    .form-top {
      text-align: center;

      .logo {
        width: 99px;
        height: 99px;
        background: #f0f3fa;
        border-radius: 30px;
        margin: 0 auto 34px;
        @include flexBox(center);
      }

      h1 {
        line-height: 1em;
        color: #3e3e3e;
      }

      p {
        font-size: 18px;
        line-height: 2em;
        color: #272727;
        margin-top: 12px;
      }
    }

    .form-box {
      width: 100%;
      margin-top: 24px;

      h5 {
        color: #3e3e3e;
        line-height: 1em;
        padding-left: 40px;
        margin-bottom: 22px;
      }

      .el-form-item {
        margin-top: 32px;

        &.code {
          ::v-deep {
            .el-input__inner {
              padding-right: 130px;
            }
          }

          .el-button {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            margin-top: 0;
          }
        }
      }

      ::v-deep .el-input__inner {
        height: 60px;
        line-height: 60px;
      }

      .el-button:not(.el-button--text) {
        width: 100%;
        font-size: 18px;
        color: #fff;
      }

      .login-form {
        .protocolTitle{
          text-align: center;
          margin-top: 50px;
          color: #666666;
          a{
            color: #01A1FD;
          }
        }
        .el-button {
          margin-top: 20px;
        }
      }

      .register-form {
        .protocolTitle{
          text-align: center;
          margin-top: 10px;
          color: #666666;
          a{
            color: #01A1FD;
          }
        }
        .el-form-item {
          margin-top: 16px;
        }

        .upload {
          margin: 32px 0;

          ::v-deep .el-upload-list__item {
            &:nth-child(3n) {
              margin-right: 0 !important;
            }
          }

          &.hide-box {
            ::v-deep .el-upload--picture-card {
              display: none;
            }
          }
        }
      }

      .password-form {
        .el-form-item {
          margin-top: 24px;
        }

        .el-button {
          margin-top: 56px;
        }
      }
    }

    .img-verify {
      width: 548px;
      height: 498px;
      background: #fff;
      box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      margin-top: -22px;
      box-sizing: border-box;
      padding: 14px 14px 20px 24px;

      &--head {
        color: #474747;
        margin-bottom: 18px;
        @include flexBox(space-between);

        .iconfont {
          font-size: 36px;
          cursor: pointer;
          padding: 0 10px;
          color: #cecece;
        }
      }
    }

    .tool {
      margin-top: 1em;
      height: 2em;
      color: #555;
      box-sizing: border-box;
      padding: 0 32px 0 22px;
      @include flexBox(space-between);

      span {
        cursor: pointer;
        @include defaultAni;

        &:hover {
          color: $color-primary;
        }

        &:last-child {
          margin-left: auto;
          color: #737373;
        }
      }
    }
  }
}
</style>

<template>
  <div class="login">
    <img class="login-bg" src="@assets/images/login-bg.jpg" alt="">
    <div class="login-container">
      <div class="form-top">
        <p class="logo">
          <img src="@assets/images/logo.png" alt="">
        </p>
        <h1 class="bold">欢迎登录</h1>
        <p>育材地学校端</p>
      </div>
      <div class="form-box">
        <!-- 登录表 -->
        <el-form class="login-form" ref="loginForm" :rules="loginRules" :model="loginForm" v-if="formType == 1">
          <el-form-item prop="user_name">
            <el-input v-model.trim="loginForm.user_name" maxlength="11" placeholder="请输入账号" />
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model.trim="loginForm.password" type="password" maxlength="16" placeholder="请输入密码" />
          </el-form-item>
          <el-form-item>
            <div class="protocolTitle">
              注册即代表我已同意<a href="https://api.zytycd.com/protal/common/protocol?orgin=school&type=serve" target="_blank">《用户服务协议》</a>和 <a href="https://api.zytycd.com/protal/common/protocol?orgin=school&type=privacy" target="_blank">《隐私政策》</a>
            </div>
            <el-button class="bold" type="custom_primary" @click="handleLogin">登录</el-button>
          </el-form-item>
        </el-form>
        <!-- 注册表 -->
        <el-form class="register-form" :rules="registerRules" ref="registerForm" :model="registerForm"
          v-if="formType == 2">
          <h5 class="bold">学校入驻</h5>
          <el-form-item prop="scuse_phone">
            <el-input v-model.trim="registerForm.scuse_phone" maxlength="11" placeholder="请输入手机号" clearable
              @input="registerForm.scuse_phone = iptInit(registerForm.scuse_phone)" />
          </el-form-item>
          <el-form-item prop="code" class="code">
            <el-input v-model.trim="registerForm.code" ref="codeIpt" type="text" maxlength="100" placeholder="请输入验证码" />
            <el-button type="text" class="primary" @click.stop="getCode" :disabled="codeSend">
              {{ codeSend && `${codeCountdown}s后可重新获取` || '获取验证码' }}
            </el-button>
          </el-form-item>
          <el-form-item prop="scuse_password">
            <el-input v-model.trim="registerForm.scuse_password" type="password" maxlength="16"
              placeholder="请设置登录密码(8~16位)" />
          </el-form-item>
          <el-form-item prop="area">
            <el-cascader v-model="registerForm.area" ref="schoolArea" clearable :options="provinceData"
              :props="{ value: 'ddi_id', label: 'ddi_name' }" placeholder="请选择区域" @change="changeProvince" />
          </el-form-item>
          <el-form-item prop="scsch_id">
            <el-select v-model="registerForm.scsch_id" filterable clearable placeholder="请选择学校"
              @change="$refs.registerForm.validateField('scsch_id')">
              <el-option v-for="item in schoolData" :key="item.scsch_id" :label="item.scsch_name"
                :value="item.scsch_id" />
            </el-select>
          </el-form-item>
          <el-form-item class="upload"
            :class="{ 'hide-box': registerForm.scsch_qualifica && registerForm.scsch_qualifica.length == 6 }"
            prop="scsch_qualifica">
            <el-upload :action="$upload.imgAction" :accept="$upload.imgAccept" :before-upload="$beforImgUpload"
              list-type="picture-card" :limit="6" multiple :on-exceed="() => $message.error('最多上传6张图片')"
              :on-remove="handleRemove" :on-success="handleSuccess">
              <p class="iconfont">&#xe603;</p>
              <p class="tips">上传学校资质</p>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <div class="protocolTitle">
              注册即代表我已同意<a href="https://api.zytycd.com/protal/common/protocol?orgin=school&type=serve" target="_blank">《用户服务协议》</a>和 <a href="https://api.zytycd.com/protal/common/protocol?orgin=school&type=privacy" target="_blank">《隐私政策》</a>
            </div>
            <el-button class="bold" type="custom_primary" :disabled="btnload" @click="handleRegister"
              v-loading="btnload">注册</el-button>
          </el-form-item>
        </el-form>
        <!-- 忘记密码表 -->
        <el-form class="password-form" ref="passwordForm" :rules="passwordRules" :model="passwordForm"
          v-if="formType == 3">
          <h5 class="bold">忘记密码</h5>
          <el-form-item prop="scuse_phone">
            <el-input v-model.trim="passwordForm.scuse_phone" maxlength="11" placeholder="请输入手机号" clearable
              @input="passwordForm.scuse_phone = iptInit(passwordForm.scuse_phone)" />
          </el-form-item>
          <el-form-item prop="code" class="code">
            <el-input v-model.trim="passwordForm.code" ref="codeIpt" type="text" maxlength="100" placeholder="请输入验证码" />
            <el-button type="text" class="primary" @click.stop="getCode" :disabled="codeSend">
              {{ codeSend && `${codeCountdown}s后可重新获取` || '获取验证码' }}
            </el-button>
          </el-form-item>
          <el-form-item prop="scuse_change_pwd">
            <el-input v-model.trim="passwordForm.scuse_change_pwd" type="password" maxlength="16"
              placeholder="请输入新密码(8~16位)" />
          </el-form-item>
          <el-form-item prop="scuse_change_pwd_confirm">
            <el-input v-model.trim="passwordForm.scuse_change_pwd_confirm" type="password" maxlength="16"
              placeholder="请再次输入新密码(8~16位)" />
          </el-form-item>
          <el-form-item>
            <el-button class="bold" type="custom_primary" :disabled="btnload" @click="handleReset"
              v-loading="btnload">确认</el-button>
          </el-form-item>
        </el-form>
        <!-- 图片安全验证 -->
        <div class="img-verify" v-if="formType == 4">
          <p class="img-verify--head">
            <span>请完成安全验证码</span>
            <span class="iconfont" @click="changeForm(1)">&#xe604;</span>
          </p>
          <slideVerify slider-text="向右拖动滑块填充拼图" ref="imgVerify" :blockSlide="58" :blockRadius="10" :sliderWidth="410"
            :w="500" :h="306" :blockLine="0" :accuracy="5" :imgs="picArray" @success="verifySuccess" @fail="verifyFail"
            @again="verifyFail" @refresh="getImgs" />
        </div>
        <!-- 表单切换 -->
        <p class="tool">
          <template v-if="formType == 1 || formType == 2">
            <span @click="changeForm(2)" v-if="formType == 1">无账号？去注册</span>
            <span @click="changeForm(1)" v-if="formType == 2">有账号，去登录</span>
            <span @click="changeForm(3)">忘记密码</span>
          </template>
          <span @click="changeForm(1)" v-if="formType == 3">去登录</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import slideVerify from "@/components/slideVerify";
import { getPhoneCode, getProvinceCity, getSchool, $verifyImgs } from "@/api/common";
import { formatFile, iptInteger } from "@utils"
import { validPassword, validPhone } from "@utils/validate";
import { schoolRegister, schoolLogin, forgotPassword } from "@/api/school";
import { mapActions } from "vuex";
export default {
  name: "Login",
  components: {
    slideVerify
  },
  computed: {
    iptInit() {
      return function (val) {
        return iptInteger(val, '')
      }
    },
  },
  data() {
    // 电话号码校验
    let validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入手机号"));
      } else if (!validPhone(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    };
    // 密码校验
    let validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } else if (value.length < 8 || value.legnth > 16) {
        callback(new Error("请输入8~16位密码"));
      } else if (!validPassword(value)) {
        callback(new Error("密码包含数字、大写字母、小写字母、特殊字符"));
      } else {
        callback();
      }
    };
    // 再次输入密码校验
    let validatePass2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordForm.scuse_change_pwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    let validateImg = (rule, value, callback) => {
      if (this.$isEmpty(this.registerForm.scsch_qualifica)) {
        callback(new Error("请上传至少一张学校资质"));
      } else {
        callback();
      }
    };
    return {
      picArray: [], // 拖动校验背景图
      formType: 1, // 操作表单类型: 1=>登录 2=>注册 3=>忘记密码 4=>滑动校验码
      loginForm: { user_name: '', password: '' }, // 登录表单
      registerForm: { scsch_qualifica: [] }, // 注册表单
      passwordForm: {}, // 密码表单
      provinceData: [], // 省市区数据
      schoolData: [], // 学校数据
      /* 登录表单校验规则 */
      loginRules: {
        user_name: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      /* 注册表单校验规则 */
      registerRules: {
        scuse_phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        scuse_password: [{ required: true, validator: validatePass, trigger: "blur" }],
        area: [{ type: "array", required: true, message: "请选择地区", trigger: "change" }],
        scsch_id: [{ required: true, message: "请选择学校", trigger: "change" }],
        scsch_qualifica: [{ type: "array", required: true, validator: validateImg, trigger: "change" }],
      },
      /* 修改密码表单校验规则 */
      passwordRules: {
        scuse_phone: [{ required: true, validator: validatePhone, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        scuse_change_pwd: [{ required: true, validator: validatePass, trigger: "blur" }],
        scuse_change_pwd_confirm: [{ required: true, validator: validatePass2, trigger: "blur" }],
      },
      codeSend: false, // 验证码发送
      codeCountdown: 60, // 重新发送验证码倒计时
      currentInterval: null, // 当前倒计时实例
      btnload: false
    };
  },
  watch: {},
  created() {
    this.getProvinceData();
    this.getImgs();
  },
  mounted() {
    // 绑定enter事件
    this.enterKeyup();
  },
  methods: {
    ...mapActions("user", ["gainUserInfo"]),

    enterKey(event) {
        const code = event.keyCode
            ? event.keyCode
            : event.which
                ? event.which
                : event.charCode;
        if (code == 13) {
            this.handleLogin();
        }
    },
    enterKeyupDestroyed() {
        document.removeEventListener("keyup", this.enterKey);
    },
    destroyed() {
    // 销毁enter事件
      this.enterKeyupDestroyed();
    },
    enterKeyup() {
        document.addEventListener("keyup", this.enterKey);
    },
    /** 删除图片 */
    handleRemove(file, fileList) {
      let fileArr = []
      fileList.map(({ response: res }) => {
        let { data } = res;
        let file_item = { url: data.file, address: data.address }
        fileArr.push(file_item);
      })
      this.registerForm.scsch_qualifica = [...fileArr];
      this.$refs.registerForm.validateField('scsch_qualifica');
    },
    /** 上传成功 */
    handleSuccess({ data, msg }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      let file_item = { url: data.file, address: data.address };
      this.registerForm.scsch_qualifica.push(file_item);
      this.$refs.registerForm.validateField('scsch_qualifica');
    },
    /** 获取省市区数据 */
    async getProvinceData() {
      let { data } = await getProvinceCity();
      data.forEach((item) => {
        if(item.children){
          item.children = item.children.filter((citem) => citem.ddi_id != -1);
          item.children.map(
            (citem) => citem.children.length == 0 && delete citem.children
          );
          item.children.forEach((citem) => {
            if(citem.children){
              citem.children = citem.children.filter((aitem) => aitem.ddi_id != -1);
              citem.children.map(
                (aitem) => aitem.children.length == 0 && delete aitem.children
              );
            }
          });
        }
      });
      this.provinceData = data;
      this.$forceUpdate();
    },
    /**
     * 获取省市区下属学校
     * @param {Array} params 省市区数据
     */
    async getSchool(params) {
      let { data } = await getSchool({
        scsch_province: params[0],
        scsch_city: params[1],
        scsch_area: params[2],
      });
      this.schoolData = data;
      this.$forceUpdate();
    },
    /**
     * 省市区选中，获取学校数据
     * @param {Array} val 选中节点的值
     */
     changeProvince(val) {
      if (this.$isEmpty(val)) {
        this.registerForm.area = [];
        this.$nextTick(() => {
          this.$refs.schoolArea.$refs.panel.clearCheckedNodes();
          this.$refs.schoolArea.$refs.panel.activePath = [];
        })
      }
      delete this.registerForm.scsch_id;
      this.$nextTick(() => {
        this.$refs.registerForm.validateField('area');
      })
      this.getSchool(val);
    },
    /**
     * 切换操作表单
     */
    changeForm(type) {
      this.$nextTick(() => {
        if (type != 4 && this.formType != 4) this.loginForm = {};
        this.registerForm = {};
        this.registerForm = { scsch_qualifica: [] };
        this.passwordForm = {};
        if (this.$refs.loginForm) this.$refs.loginForm.clearValidate();
        if (this.$refs.passwordForm) this.$refs.passwordForm.clearValidate();
        if (this.$refs.registerForm) this.$refs.registerForm.clearValidate();
        this.formType = type;
        this.resetTime();
        this.$forceUpdate();
      })
    },
    /** 获取验证图片 */
    async getImgs() {
      let { data } = await $verifyImgs();
      // this.picArray = ['/storage/upload/img/20230612/760a7202306120924592394.jpg'];
      this.picArray = data;
    },
    /**
     * 登录
     */
    handleLogin() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          await this.getImgs();
          if (this.$refs.imgVerify) this.$refs.imgVerify.refresh() // 图片校验刷新
          this.formType = 4;
          this.$refs.loginForm.clearValidate();
        }
      });
    },
    /**
     * 注册
     */
    handleRegister() {
      this.$refs.registerForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            ...this.registerForm,
            scsch_qualifica: this.registerForm.scsch_qualifica.map(item => item.address),
            scsch_province: this.registerForm.area[0],
            scsch_city: this.registerForm.area[1],
            scsch_area: this.registerForm.area[2],
          };
          delete params.area
          let res = await schoolRegister(params);
          this.btnload = false;
          // 注册成功后确认登录
          if (res.status == 200) {
            this.$message.success("注册成功");
            setTimeout(() => {
              this.changeForm(1);
            }, 300);
          }
        }
      });

    },
    /**
     * 修改密码
     */
    handleReset() {
      this.$refs.passwordForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            ...this.passwordForm,
          };
          const res = await forgotPassword(params);
          this.btnload = false;
          if (res.status == 200) {
            this.$message.success("修改成功");
            setTimeout(() => {
              this.changeForm(1);
            }, 300);
          }
        }
      });
    },
    /** 拖拽验证成功 */
    async verifySuccess() {
      let res = await schoolLogin(this.loginForm);
      // 注册成功后确认登录
      if (res) {
        this.loginForm = {};
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("prikey", res.data.prikey);
        this.gainUserInfo();
        if(res.data.check_data.isupdatepwd!=1){
          sessionStorage.removeItem("updatePwd")
          this.$message.success("登录成功");
          setTimeout(() => {
            this.$router.replace({ name: "HOME" });
          }, 1500);
        }else{
          sessionStorage.setItem("updatePwd", true);
          this.$message.warning("为了您的账户安全，请修改密码");
          setTimeout(() => {
            this.$router.replace({ name: "PERSON" });
          }, 1500);
        }
      } else {
        setTimeout(() => {
          this.changeForm(1);
        }, 300);
      }
    },
    /** 拖拽验证失败 */
    async verifyFail() {
      await this.getImgs();
      this.$refs.imgVerify.refresh();
    },
    /** 获取验证码 */
    async getCode() {
      let phone;
      if (this.formType == 2) {
        this.$refs.registerForm.validateField('scuse_phone', error => {
          if (!error) phone = this.registerForm.scuse_phone;
        });
      } else if (this.formType == 3) {
        this.$refs.passwordForm.validateField('scuse_phone', error => {
          if (!error) phone = this.passwordForm.scuse_phone;
        });
      }
      if (phone) {
        let res = await getPhoneCode({ phone })
        if (res) {
          this.codeSend = true;
          this.timeCountdown();
          this.$message.success('验证码发送成功，请注意查收');
        }
      }
    },
    /** 时间倒计时 */
    timeCountdown() {
      clearInterval(this.currentInterval);
      this.currentInterval = setInterval(() => {
        if (this.codeCountdown == 1) {
          this.resetTime();
        } else {
          this.codeCountdown -= 1
        }
      }, 1000);
    },
    /** 重置时间倒计时 */
    resetTime() {
      this.codeCountdown = 60;
      this.codeSend = false;
      clearInterval(this.currentInterval);
    },
  },
};
</script>
