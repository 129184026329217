/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: wang hai yang
 * @LastEditTime: 2023-05-11 10:37:47
 * @Descripttion: 用户管理模块接口
 */
import HTTP from "@utils/http";
const SCHOOL = "/school/schschool/";
/**
 * 注册
 * @param {object} params
 * @returns
 */
export async function schoolRegister(params) {
  try {
    return await HTTP.post(SCHOOL + "register", params);
  } catch (error) {
    return error;
  }
}
/**
 * 登录
 * @param {object} params
 * @returns
 */
export async function schoolLogin(params) {
  try {
    return await HTTP.post(SCHOOL + "login", params);
  } catch (error) {
    return error;
  }
}
/**
 * 忘记密码
 * @param {object} params
 * @returns
 */
export async function forgotPassword(params) {
  try {
    return await HTTP.post("/school/schuser/forgot_password", params);
  } catch (error) {
    return error;
  }
}
/**
 * 编辑学校
 * @param {object} params
 * @returns
 */
export async function schoolEdit(params) {
  try {
    return await HTTP.post(SCHOOL + "edit", params);
  } catch (error) {
    return error;
  }
}
/**
 * 获取学校信息
 * @param {object} params
 * @returns
 */
export async function schoolInfo() {
  try {
    return await HTTP.post(SCHOOL + "info");
  } catch (error) {
    return error;
  }
}
// /**
//  * 获取学校大事件
//  * @param {object} params
//  * @returns
//  */
// export async function memorabilia() {
//   try {
//     return await HTTP.post(SCHOOL + "memorabilia");
//   } catch (error) {
//     return error;
//   }
// }